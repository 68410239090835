@tailwind base;
/* purgecss start ignore */
@tailwind components;
@tailwind utilities;
/* purgecss end ignore */

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow: hidden;
  min-width: 790px;
  font-size: 14px;
}

.bp3-toast-container-top {
  z-index: 9999999;
}

#smb-root {
  height: 100%;
}

.smb-help-text {
  font-weight: normal;
  font-size: 10px;
}

.text-top-bottom {
  writing-mode: vertical-lr;
  -webkit-writing-mode: vertical-lr;
  -ms-writing-mode: vertical-lr;
  height: 290px;
}

.smb-help-wrapper {
  padding: 5px;
  max-width: 500px;
}

.smb-link {
  cursor: pointer;
  display: inline-block;
  text-decoration: underline;
}

.smb-spacer {
  height: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #9ca3af;
}

h1 {
  @apply font-bold text-lg;
  margin-left: 0 !important;
}

select {
  @apply pt-1 pb-1 w-full;
  max-width: 400px;
}

.smb-link {
  @apply hover:text-blue-700;
  cursor: pointer;
  display: inline-block;
  text-decoration: underline;
}

.loading-spinner-wrapper {
  width: 300px;
  height: 200px;
  position: absolute;
  left: 50%;
  top: 100px;
  transform: translate(-50%, 50%);
  padding: 20px;
}

.spinner-message {
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  position: absolute;
  top: 150px;
  left: 0;
  right: 0;
}

svg {
  height: 100%;
  width: 100%;
}

[data-testid="input-group"] {
  width: 100%;
}

.cell:nth-child(odd) {
  background-color: #cbd5e0;
}

.cell:nth-child(odd).cell-hover {
  @apply bg-hover !important;
}

.grid-spacer:last-child {
  display: none !important;
}

.order-area:first-child {
  margin-top: 0;
}

.divTableRow:last-child {
  border-bottom: none;
}

@-webkit-keyframes smb-loader {
  0% {
    background: rgba(206, 217, 224, 0.2);
    border-color: rgba(206, 217, 224, 0.2);
  }
  to {
    background: rgba(92, 112, 128, 0.2);
    border-color: rgba(92, 112, 128, 0.2);
  }
}

@keyframes smb-loader {
  0% {
    background: rgba(206, 217, 224, 0.2);
    border-color: rgba(206, 217, 224, 0.2);
  }
  to {
    background: rgba(92, 112, 128, 0.2);
    border-color: rgba(92, 112, 128, 0.2);
  }
}

.smb-cell-loader {
  -webkit-animation: smb-loader linear infinite alternate;
  animation: smb-loader linear infinite alternate;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  height: 10px;
  top: 50%;
  margin: 0 10px;
  position: relative;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

[draggable="true"] {
  cursor: move;
}

[draggable="false"] {
  cursor: not-allowed;
}

.arrow-top:after,
.arrow-top:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow-top:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
  border-width: 10px;
  margin-left: -10px;
}
.arrow-top:before {
  border-color: rgba(112, 86, 245, 0);
  border-bottom-color: rgba(156, 163, 175, 1);
  border-width: 11px;
  margin-left: -11px;
}

.arrow-left:after,
.arrow-left:before {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow-left:after {
  border-color: rgba(255, 255, 255, 0);
  border-right-color: #ffffff;
  border-width: 10px;
  margin-top: -10px;
}
.arrow-left:before {
  border-color: rgba(112, 86, 245, 0);
  border-right-color: rgba(156, 163, 175, 1);
  border-width: 11px;
  margin-top: -11px;
}

.arrow-bottom:after,
.arrow-bottom:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow-bottom:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #ffffff;
  border-width: 10px;
  margin-left: -10px;
}
.arrow-bottom:before {
  border-color: rgba(112, 86, 245, 0);
  border-top-color: rgba(156, 163, 175, 1);
  border-width: 11px;
  margin-left: -11px;
}

.arrow-right:after,
.arrow-right:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow-right:after {
  border-color: rgba(255, 255, 255, 0);
  border-left-color: #ffffff;
  border-width: 10px;
  margin-top: -10px;
}
.arrow-right:before {
  border-color: rgba(112, 86, 245, 0);
  border-left-color: rgba(156, 163, 175, 1);
  border-width: 11px;
  margin-top: -11px;
}

.tooltip {
  max-width: 400px;
  left: 0px;
  top: 0px;
  opacity: 0;
  z-index: 9999;
}
