.smb-work-badge {
  background-color: #9ca3af;
  width: 150px;
  height: 25px;
  border-width: 2px;
  border-color: white;
}

.smb-work-badge.smb-1 {
  border-color: #0369a1;
}

.smb-work-badge.smb-2 {
  border-color: #b91c1c;
}

.smb-work-badge.smb-99 {
  border-color: #65a30d;
}

.smb-work-badge.smb-50 {
  border-color: #84cc16;
}

.smb-work-badge.smb-110 {
  border-color: #65a30d;
}

.smb-work-badge.smb-120 {
  border-color: #16a34a;
}

.smb-work-badge-status {
  width: 35px;
  position: relative;
  background-color: white;
  padding-top: 2px;
}

.smb-work-badge-label {
  color: white;
  padding: 4px;
}
